import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { ISearchTitle } from 'gatsby-theme-gaviscon/src/components/SearchTitle/models';

import 'gatsby-theme-gaviscon/src/components/SearchTitle/SearchTitle.scss';
import './SearchTitleOverride.scss';

const SearchTitle: FC<ISearchTitle> = ({
  searchResults,
  totalResultsCount,
  singleResultFor,
  resultsFor,
  cantFind,
}) => (
  <h1 className="search-result__page-title">
    {totalResultsCount === 0 ? (
      <DangerouslySetInnerHtml
        element="span"
        className="not-found-qty"
        html={searchResults.query || String.fromCharCode(160)}
      />
    ) : null}
    {`${
      searchResults.query
        ? totalResultsCount
          ? `${totalResultsCount} ${
              totalResultsCount === 1 ? singleResultFor : resultsFor
            }`
          : cantFind
        : resultsFor
    }`}
    {totalResultsCount > 0 ? (
      <DangerouslySetInnerHtml
        element="span"
        html={searchResults.query || String.fromCharCode(160)}
      />
    ) : null}
  </h1>
);

export default SearchTitle;
